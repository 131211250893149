/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import "./layout.css"
import Wrapper from "./Wrapper"
import { StaticImage } from "gatsby-plugin-image"
import { Carousel } from 'flowbite-react/lib/cjs/components/Carousel';


const FotoPozicovne = ({ children }) => {

  return (
    <>
      
      <Wrapper>
       <div className="grid grid-cols-1 ">
       <div className="col-span-1 xl:pt-36 md:pt-12 pl-2 xl:pl-4">
            <h6 className="xl:text-xl md:text-md block xl:pt-20 lg:pt-24 pt-8 pb-6  leading-8 italic">Vitajte v nových priestoroch</h6>
            <h2 className="block pb-12 xl:text-6xl text-3xl leading-tight font-semibold">Foto<br className="hidden md:inline"/> požičovňe</h2>
        </div>
        <div className="col-span-1 justify-self-center relative w-full ">
       <div className="hidden md:block "><StaticImage placeholder="blurred" className="absolute  lg:top-[-250px]  md:top-[-200px] left-12 mx-auto"  imgClassName="" src="../../static/images/landing/foto01.png" alt="skiers" /></div>
        </div>
          <div className="h-96  md:hidden">
  <Carousel >
  <StaticImage placeholder="blurred" className=""  imgClassName="" src="../../static/images/carousel/carousel01.jpg" alt="skiers" />
  <StaticImage placeholder="blurred" className=""  imgClassName="" src="../../static/images/carousel/carousel02.jpg" alt="skiers" />
  <StaticImage placeholder="blurred" className=""  imgClassName="" src="../../static/images/carousel/carousel03.jpg" alt="skiers" />
  <StaticImage placeholder="blurred" className=""  imgClassName="" src="../../static/images/carousel/carousel04.jpg" alt="skiers" />

  </Carousel>
</div>
        </div>
      </Wrapper>
   
    </>
  )
}

export default FotoPozicovne
