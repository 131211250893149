import * as React from "react"
import Seo from "../components/seo"
import News from "../components/news";
import Layout from "../components/layout";
import Intro from "../components/Intro";
import IntroSluzby from "../components/IntroSluzby";
import IntroSluzbySocial from "../components/IntroSluzbySocial";
import FotoPozicovne from "../components/FotoPozicovne";
import LandingContact from "../components/LandingContact";
import Contact from "../components/Contact";
import "./index.css"
import Pricelist from "../components/pricelist";
import Wrapper from "../components/Wrapper";


const LandingPage = () => {
    return (
        <div>
            <Layout>
            <div className="bg-gray-100"><div className=" container  mx-auto"><News></News></div></div>

            {/* <Pricelist tags="rent"></Pricelist> */}
            <Intro></Intro>
            <IntroSluzby></IntroSluzby>
            <IntroSluzbySocial></IntroSluzbySocial>
            <FotoPozicovne></FotoPozicovne>
            <Contact className="block xl:hidden"></Contact>
            </Layout>
        </div>
    )
}

export const Head = () => <Seo title="Home" />

export default LandingPage