/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import "./layout.css"
import Wrapper from "./Wrapper"
import { StaticImage } from "gatsby-plugin-image"


const IntroSluzby = ({ image }) => {

  return (
    <>
      
      <Wrapper>
       <div className="grid grid-cols-3 gap-4">
       <div className="col-span-3 pl-2 xl:pl-4">
            <h6 className="xl:text-xl md:text-md block pt-20 pb-6  leading-8 italic">Lyžiarska sezóna sa začína u nás</h6>
            <h2 className="block md:pb-12 pb-4 xl:text-6xl text-3xl leading-tight font-semibold">Naše služby a novinky</h2>
        </div>
        <div className="col-span-3 md:col-span-1 border-b-4 hover:border-yellow hover:cursor-pointer hover:opacity-50 transition duration-700 ease-in-out border-gray-200 justify-self-center">
        <a href="/sezonne">
        <StaticImage width={420} placeholder="blurred" className="mx-auto"  imgClassName="" src="../../static/images/landing/sluzby01.png" alt="skiers" />
        <h4 className="block my-4 text-2xl font-semibold"> Požičovňa</h4>
        </a>
        </div>
        <div className="col-span-3 md:col-span-1 border-b-4 hover:border-yellow hover:cursor-pointer hover:opacity-50 transition duration-700 ease-in-out border-gray-200 justify-self-center">
        <a href="/kurzy">
        <StaticImage width={420} placeholder="blurred" className="mx-auto"  imgClassName="" src="../../static/images/landing/sluzby02.png" alt="skiers" />
        <h4 className="block my-4 text-2xl font-semibold"> Lyžiarske kurzy</h4>
        </a>
        </div>
        <div className="col-span-3 md:col-span-1 border-b-4 hover:border-yellow hover:cursor-pointer hover:opacity-50 transition duration-700 ease-in-out border-gray-200 justify-self-center">
        <a href="/servis">
        <StaticImage width={420} placeholder="blurred" className="mx-auto"  imgClassName="" src="../../static/images/landing/sluzby03.png" alt="skiers" />
        <h4 className="block my-4 text-2xl font-semibold"> Skiservis</h4>
        </a>
        </div>
       </div>
      </Wrapper>
   
    </>
  )
}

export default IntroSluzby
