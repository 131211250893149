import * as React from "react"
import "./layout.css"
import Wrapper from "./Wrapper"
import { StaticImage } from "gatsby-plugin-image"
import {useEffect, useState} from "react"
import axios from "axios";


const IntroSluzby = ({ image }) => {

  const [instagram, setInstagram] = useState([0]);
  const headers = {
      'Authorization': process.env.GATSBY_DIRECTUS_TOKEN,
      'Content-Type': 'application/json'
  };
  const url = `${process.env.GATSBY_DIRECTUS_URL}/items/instagram?filter[id][_eq]=1`;
  useEffect(() => {
      axios({
          url: url,
          method: 'GET',
          headers: headers
      })
          .then((result)=>{
            let photo = result.data.data.map(( object , index) => (
              {
                src: process.env.GATSBY_DIRECTUS_URL + "/assets/" + object.image + "?access_token=" + process.env.GATSBY_TOKEN_FOR_IMAGE,
                text: object.text
              }));
        console.log(photo);
        setInstagram(photo);
          })
          .catch(error => {
              console.log(error);
          });
  }, []);

  

  return (
    <>
      
      <Wrapper>
       <div className="grid grid-cols-2 gap-8 mb-8">
       <div className="col-span-2 pl-2 xl:pl-4">
            <h6 className="xl:text-xl md:text-md block pt-20 pb-6  leading-8 italic">Najnovšie príspevky</h6>
            <h2 className="block md:pb-12 pb-4 xl:text-6xl text-3xl leading-tight font-semibold">Naše sociálne siete</h2>
        </div>
        <div className="  rounded-lg shadow-lg col-span-2 border-1 md:col-span-1  hover:cursor-pointer hover:opacity-50 transition duration-700 ease-in-out border-gray-200 justify-self-center">
        <a href="https://www.facebook.com/profile.php?id=100057536894105">
          <div className="grid grid-cols-2">
          <div className="col-span-2 lg:col-span-1 aspect-square">
          <StaticImage width={420} className="mx-auto" placeholder="blurred" src="../../static/images/facebook.jpg" alt="skiers" />
          </div>
          <div className="col-span-2 lg:col-span-1">
          <h4 className="block my-4 text-2xl font-semibold pl-4">Facebook</h4>
          <p className="block my-4 text-md font-regular p-6"> 
          OD  15.10. 2022 POŽIČOVŇA OTVORENÁ  Po-Pia 12h - 18h, 
          Sob 9-13h. 
          PRESŤAHOVALI SME SA NA Gorkého 6
            (naproti Hotelu Yasmin)</p>
          </div>
        </div>
        </a>
        </div>
        <div className=" rounded-lg shadow-lg col-span-2 md:col-span-1 border-1 hover:cursor-pointer hover:opacity-50 transition duration-700 ease-in-out border-gray-200 justify-self-center">
        <a href="https://www.instagram.com/pozicovnalyzikosice/">
        <div className="grid grid-cols-2">
          <div className="col-span-2 lg:col-span-1 aspect-square">
          <img width={420} placeholder="blurred" className="mx-auto" src={instagram[0].src} alt="skiers" />
          </div>
          <div className="col-span-2 lg:col-span-1">
          <h4 className="block my-4 text-2xl font-semibold pl-4">Instagram</h4>
          <p className="block my-4 text-md font-regular p-6"> {instagram[0].text}</p>
          </div>
        </div>
        

        </a>
        </div>


       </div>
      </Wrapper>
   
    </>
  )
}

export default IntroSluzby
